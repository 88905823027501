import { Routes } from "types";
import { Condominiums } from "views/dashboard";

import { Home } from "@material-ui/icons";

export const adminRoutes: Routes = [
  {
    path: "/condominiums",
    name: "Locais",
    icon: Home,
    component: Condominiums,
    layout: "/dashboard",
  },
  /*  {
      path: "/list/deliveries",
      name: "Suas entregas",
      //rtlName: "لوحة القيادة",
      icon: "inventory_2",
      component: ListDeliveries,
      layout: "/admin",
    }, */
];
