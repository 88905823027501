import React, { useRef, useState } from "react";

import MaterialTable from "material-table";

import { Typography, Grid, Button, TextField, Paper } from "@material-ui/core";

import axios from "config/axios";
import { Condominium } from "types";
import { useFormik } from "formik";

import * as yup from "yup";
import CustomLoading from "components/CustomLoading";

//import useStyles from "./styles";

interface Props {
  selectedCondominium?: Condominium;
  alert: any;
  setMode: React.Dispatch<React.SetStateAction<number>>;
  getCondominiums: () => Promise<void>;
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(4, "Mínimo de 4 caracteres")
    .required("A senha é obrigatória"),
});

const Managers: React.FC<Props> = (props) => {
  const condominiumId = props?.selectedCondominium?._id;
  const selectedCondominium = props.selectedCondominium;

  const [loading, setLoading] = useState(false);

  const tableRef = useRef<any>();

  const alterAlert = props.alert.alterAlert;

  const updatePwd = async (values: { password: string }) => {
    setLoading((oldState) => !oldState);
    axios
      .put("/aptmanager/update/tablet/password", {
        password: values.password,
        condominiumId,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        formik.resetForm();
        props.alert?.alterAlert("Senha atualizada com sucesso", "success");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.code) {
          default:
            props.alert?.alterAlert("Problema ao atualizar senha", "error");
            break;
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (e) => {
      updatePwd(e);
    },
  });

  return (
    <div>
      <CustomLoading loading={loading} />
      <MaterialTable
        options={{ exportButton: true, grouping: true, search: true }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "Nome",
            field: "user.name",
            type: "string",
          },
          {
            title: "Email",
            field: "user.email",
            type: "string",
          },
          {
            title: "_id",
            field: "_id",
            type: "string",
            hidden: true,
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/aptmanager/condominium/aptmanagers`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                condominiumId: condominiumId,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error?.response?.data?.code) {
                  default:
                    props.alert?.alterAlert(
                      "Problema ao carregar dados",
                      "error"
                    );
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowDelete: async (oldData) => {
            try {
              const { data } = await axios.delete(
                `/aptmanager/condominium/delete/manager/${condominiumId}/${oldData._id}`
              );
              if (data.redirect) {
                props.setMode(0);
                props.getCondominiums();
              }
              alterAlert("Bloco removido com sucesso", "success");
              tableRef.current && tableRef?.current?.onQueryChange();
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Condomínio não encontrado", "warning");
                  break;
                case 1:
                  alterAlert("Síndico não encontrado", "warning");
                  break;
                default:
                  alterAlert("Problema ao remover síndico", "error");
                  break;
              }
            }
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
            deleteTooltip: "Excluir síndico",
            addTooltip: "Adicionar síndico",
            editTooltip: "Editar síndico",
            editRow: {
              deleteText: "Excluir síndico?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Síndicos"
      />
      <Typography align="center" variant="body1" style={{ marginTop: 10 }}>
        Código do condomínio: <b>{selectedCondominium?.cod}</b>
      </Typography>
      <br />
      <Paper style={{ padding: 20 }}>
        <Typography align="center" variant="h6">
          Atualizar senha de acesso ao tablet
        </Typography>
        <Grid container direction="row" spacing={2} justify="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Nova senha de acesso"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => formik.handleSubmit()}
              disabled={loading}
            >
              Atualizar senha
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Managers;
