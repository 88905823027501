import React, { useRef, useState, useEffect } from "react";

import MaterialTable from "material-table";

import Select, { SelectOption } from "components/Select";

import axios from "config/axios";

//import useStyles from "./styles";

import { Apartment, Block } from "types";
import { isEmail } from "functions/isEmail";
import { useCallback } from "react";
//import CustomLoading from "components/CustomLoading";

interface Props {
  condominiumId: string;
  alert: any;
}

const Blocks: React.FC<Props> = (props) => {
  const [blocks, setBlocks] = useState<Array<SelectOption>>([]),
    [selectedBlock, setSelectedBlock] = useState<SelectOption | null>(),
    [selectedApartment, setSelectedApartment] = useState<SelectOption | null>(),
    [apartments, setApartments] = useState<Array<SelectOption>>([]),
    [loading, setLoading] = useState(false);
  //const classes = useStyles();
  const alterAlert = props.alert.alterAlert;

  const getBlocks = useCallback(() => {
    axios
      .get(`/aptmanager/condominium/${props.condominiumId}/blocks`)
      .then(({ data }: { data: Array<Block> }) => {
        //console.log(data);
        const result: Array<SelectOption> = [];
        data.forEach((e) => {
          result.push({ value: e._id, label: e.name });
        });
        setBlocks(result);
      })
      .catch((error) => {
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alterAlert("Problema ao carregar blocos", "error");
            break;
        }
      });
  }, [alterAlert, props.condominiumId]);

  useEffect(() => {
    getBlocks();
  }, [getBlocks]);

  const getApartments = async (blockId?: string) => {
    setLoading(true);
    axios
      .get(
        `/aptmanager/condominium/${props.condominiumId}/${blockId}/apartments`
      )
      .then(({ data }: { data: Array<Apartment> }) => {
        setLoading(false);
        //console.log(data);
        const result: Array<SelectOption> = [];
        data.forEach((e) => {
          result.push({ value: e._id, label: e.name });
        });
        setApartments(result);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alterAlert("Problema ao carregar blocos", "error");
            break;
        }
      });
  };

  const tableRef = useRef<any>();

  return (
    <div>
      {/* <CustomLoading loading={loading} /> */}
      <MaterialTable
        isLoading={loading}
        options={{ exportButton: true, grouping: true, search: true }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "Bloco",
            field: "block.name",
            type: "string",
            editable: "onAdd",
            sorting: false,
            editComponent: (editEvent) => {
              return (
                <>
                  <Select
                    placeholder="Selecione o bloco"
                    onChange={(e) => {
                      getApartments(e?.value);
                      setSelectedBlock(e);
                      setSelectedApartment(null);
                      editEvent.onChange(e?.value);
                    }}
                    value={selectedBlock}
                    fullOptions={blocks}
                  />
                </>
              );
            },
          },
          {
            title: "Apartamento",
            field: "apartment.name",
            type: "string",
            editable: "onAdd",
            sorting: false,
            editComponent: (editEvent) => {
              return (
                <>
                  <Select
                    placeholder="Selecione o apartamento"
                    value={selectedApartment}
                    onChange={(e) => {
                      setSelectedApartment(e);
                      editEvent.onChange(e?.value);
                    }}
                    fullOptions={apartments}
                  />
                </>
              );
            },
          },
          {
            title: "Nome",
            field: "name",
            type: "string",
            defaultSort: "asc",
          },
          {
            title: "Email",
            field: "email",
            type: "string",
            validate: (rowData) => {
              return isEmail(rowData.email);
            },
          },
          {
            title: "Telefone",
            field: "cellphone",
            type: "string",
          },
          {
            title: "_id",
            field: "_id",
            type: "string",
            hidden: true,
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/aptmanager/condominium/residents`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                condominiumId: props.condominiumId,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error?.response?.data?.code) {
                  default:
                    props.alert?.alterAlert(
                      "Problema ao carregar dados",
                      "error"
                    );
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowAdd: async (newData: any) => {
            try {
              await axios.post("/aptmanager/condominium/create/resident", {
                name: newData.name,
                email: newData.email,
                cellphone: "+55" + newData.cellphone,
                block: selectedBlock?.value,
                apartment: selectedApartment?.value,
                condominium: props.condominiumId,
              });
              setSelectedApartment(null);
              setSelectedBlock(null);
              alterAlert("Morador cadastrado com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                default:
                  alterAlert("Problema ao cadastrar morador", "error");
                  break;
              }
            }
          },
          onRowUpdate: async (newData, oldData) => {
            try {
              await axios.put(`/aptmanager/condominium/update/resident`, {
                cellphone: newData.cellphone,
                email: newData.email,
                name: newData.name,
                residentId: oldData._id,
              });
              alterAlert("Atualizado com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                default:
                  alterAlert("Problema ao atualizar morador", "error");
                  break;
              }
            }
          },
          onRowDelete: async (oldData) => {
            try {
              await axios.delete(
                `/aptmanager/condominium/delete/resident/${props.condominiumId}/${oldData._id}`
              );
              alterAlert("Morador removido com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                default:
                  alterAlert("Problema ao remover morador", "error");
                  break;
              }
            }
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
            deleteTooltip: "Excluir morador",
            addTooltip: "Adicionar morador",
            editTooltip: "Editar morador",
            editRow: {
              deleteText: "Excluir morador?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Moradores"
      />
    </div>
  );
};

export default Blocks;
