import React, { useEffect, useState } from "react";

import Select, { Props } from "react-select";

import { selectStyles } from "./styles";

interface MyProps {
  options?: any[] | undefined;
  fullOptions?: Array<SelectOption>;
  onChange: Props["onChange"];
  placeholder: string;
  value?: Props["value"];
}

export interface SelectOption {
  label: string;
  value: string;
}

const SelectComponent: React.FC<MyProps> = (props) => {
  const [values, setValues] = useState<any[]>([]);

  useEffect(() => {
    const result: any[] = [];
    if (props.fullOptions) {
      return setValues(props.fullOptions);
    }
    props.options?.forEach((e) => {
      result.push({ value: e, label: e });
    });
    setValues(result);
  }, [props]);

  return (
    <Select
      options={values}
      onChange={props.onChange}
      //value={defaultOption}
      value={props.value}
      placeholder={props.placeholder}
      styles={selectStyles}
    />
  );
};

export default SelectComponent;
