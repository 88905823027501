import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firebase-messaging";
import "firebase/performance";
import "firebase/firebase-analytics";

class Fire {
  constructor() {
    let firebaseConfig = {
      apiKey: "AIzaSyBie-oGRFmYgSlITyWkPYFOdBTzghkQKUU",
      authDomain: "krypton-272419.firebaseapp.com",
      databaseURL: "https://krypton-272419.firebaseio.com",
      projectId: "krypton-272419",
      storageBucket: "krypton-272419.appspot.com",
      messagingSenderId: "878686357800",
      appId: "1:878686357800:web:6757d1baff5b656804a81d",
      measurementId: "G-9JELQ8PVJS",
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.performance();
  }
  async googleLogin() {
    let provider = new firebase.auth.GoogleAuthProvider();
    /* Api para leitura dos números de telefone */
    //provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    /* Lendo o gênero da pessoa */
    //provider.addScope("https://www.googleapis.com/auth/user.gender.read");
    /* Lendo os endereços dela */
    //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
    /* lendo a data de aniversário dela  */
    //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = result?.user?.getIdToken();
        // The signed-in user info.
        let user = result.user;
        return { user, token };
        // ...
      });
  }
  async emailRegister(email: string, password: string) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }
  async sendVerifyEmail() {
    return firebase?.auth()?.currentUser?.sendEmailVerification();
  }
  async emailLogin(email: string, password: string) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((val) => {
        return val;
      });
  }
  async passwordReset(email: string) {
    return firebase.auth().sendPasswordResetEmail(email);
  }
}

export default new Fire();
