import React, { useState, useRef } from "react";

import {
  TextField,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import useMobile from "use-mobile-detect-hook";

import useStyles from "./styles";

import { useHistory } from "react-router-dom";

import CustomAlert from "components/CustomAlert";

import firebase from "config/firebase";
import { ArrowBack } from "@material-ui/icons";

import logo from "assets/img/lockyt-branco.svg";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Entre um email válido")
    .required("O email é obrigatório"),
});

function Login() {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const mobile = useMobile();

  const alertRef = useRef<any>();

  const forgotPassword = async (values: { email: string }) => {
    setLoading(true);
    firebase
      .passwordReset(values.email)
      .then(() => {
        setLoading(false);
        alertRef?.current.alterAlert(
          "Email de troca de senha enviado! Verifique seu email e depois faça login",
          "success"
        );
        setTimeout(() => {
          history.push("/login");
        }, 7000);
      })
      .catch((error) => {
        setLoading(false);
        let { code } = error;
        switch (code) {
          case "auth/user-not-found":
            alertRef?.current.alterAlert(
              "Usuário não encontrado! CADASTRE-SE no Lockyt",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao pedir troca de senha",
              "error"
            );
            break;
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      forgotPassword(values);
    },
  });

  return (
    <div>
      {loading && <LinearProgress />}
      <CustomAlert ref={alertRef} />
      <div className={classes.gradient}>
        {!mobile.isMobile() && (
          <img className={classes.logo} src={logo} alt="Logo Lockyt" />
        )}
        <Card className={classes.leftSide} style={{ borderRadius: 0 }}>
          <CardContent className={classes.cardContent}>
            <IconButton onClick={() => history.push("/login")}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h4" className={classes.title}>
              Esqueceu a senha?
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
                style={{ marginTop: 15 }}
              >
                Pedir troca de senha
              </Button>
            </form>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </div>
    </div>
  );
}

export default Login;
