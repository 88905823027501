import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useTheme } from "@material-ui/core/styles";

import TabPanel from "components/TabPanel";
import CustomAlert from "components/CustomAlert";

import axios from "config/axios";
import { Condominium, User_AptManager } from "types";

import Blocks from "components/Blocks";
import Apartments from "components/Apartments";
import Residents from "components/Residents";

import useStyles from "./styles";
import { Add, ArrowBack } from "@material-ui/icons";

import * as yup from "yup";
import Managers from "components/Managers";

const validationSchema = yup.object({
  cod: yup
    .string()
    .min(6, "Código curto demais")
    .required("Entre com o código do condomínio"),
  username: yup.string().required("Entre com um login para uso no tablet"),
  password: yup
    .string()
    .required("Entre com a senha para uso no tablet")
    .min(4, "A senha deve ter um mínimo de 4 caracteres"),
});

const Condominiums: React.FC = () => {
  const [condominiums, setCondominiums] = useState<Array<User_AptManager>>([]),
    [selectedCondominium, setSelectedCondominium] = useState<Condominium>(),
    [showEnter, setShowEnter] = useState(false),
    [mode, setMode] = useState(0),
    [selectedTab, setSelectedTab] = useState(0);

  const classes = useStyles();

  const alertRef = useRef<any>();

  const theme = useTheme();

  const getCondominiums = async () => {
    axios
      .get("/aptmanager")
      .then(({ data }) => {
        console.log(data);
        setCondominiums(data);
      })
      .catch((error) => {
        alertRef?.current?.alterAlert("Problema ao carregar locais", "error");
        console.log(error);
      });
  };

  useEffect(() => {
    getCondominiums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShowEnter(false);
  };

  const enterCondominium = async (values: any) => {
    axios
      .post("/aptmanager/enter/condominium", {
        cod: values.cod,
        username: values.username,
        password: values.password,
      })
      .then(({ data }) => {
        alertRef?.current?.alterAlert("Cadastrado com sucesso!", "success");
        setShowEnter(false);
        getCondominiums();
      })
      .catch((error) => {
        console.log(error);
        switch (error?.response?.data?.status) {
          case 1:
            alertRef?.current?.alterAlert(
              "Condomínio não encontrado",
              "warning"
            );
            break;
          case 2:
            alertRef?.current?.alterAlert(
              "Número máximo de síndicos atingido",
              "warning"
            );
            break;
          case 3:
            alertRef?.current?.alterAlert(
              "Nome de usuário já em uso por outro síndico",
              "warning"
            );
            break;
          default:
            alertRef?.current?.alterAlert(
              "Problema ao entrar nos locais",
              "error"
            );
            break;
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      cod: "",
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      enterCondominium(values);
    },
  });

  const renderSelector = useCallback(() => {
    return (
      <div>
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Seus locais
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => setShowEnter(true)}
              style={{ marginBottom: 8 }}
            >
              <Add />
            </IconButton>
          </Grid>
        </Grid>
        {!condominiums || condominiums.length === 0 ? (
          <Paper className={classes.paper}>
            <Typography variant="h6" align="center">
              Começe cadastrando um novo condomínio
            </Typography>
            <IconButton onClick={() => setShowEnter(true)}>
              <Add />
            </IconButton>
          </Paper>
        ) : (
          condominiums.map((e, index) => (
            <Card style={index > 0 ? { marginTop: 10 } : {}} key={index}>
              <CardContent>
                <Typography variant="h5">
                  Condomínio <b>{e.condominium.name}</b>
                </Typography>
                <Typography variant="body1">
                  <b>{e.condominium.address}</b>
                </Typography>
              </CardContent>
              <CardActions style={{ float: "right" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    console.log(e.condominium._id);
                    setMode(1);
                    setSelectedCondominium(e.condominium);
                  }}
                >
                  Selecionar
                </Button>
              </CardActions>
            </Card>
          ))
        )}
      </div>
    );
  }, [classes, condominiums]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const renderTabs = () => {
    return (
      <>
        <CustomAlert ref={alertRef} />
        <IconButton
          onClick={() => {
            setSelectedCondominium(undefined);
            setMode(0);
          }}
          className={classes.backArrowBtn}
        >
          <ArrowBack />
        </IconButton>
        <div className={classes.tabsContainer}>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Blocos" />
              <Tab label="Apartamentos" />
              <Tab label="Moradores" />
              <Tab label="Síndicos" />
            </Tabs>
          </AppBar>
          <div>
            <TabPanel value={selectedTab} index={0} dir={theme.direction}>
              <Blocks
                condominiumId={selectedCondominium?._id || ""}
                alert={alertRef.current}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1} dir={theme.direction}>
              <Apartments
                condominiumId={selectedCondominium?._id || ""}
                alert={alertRef.current}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={2} dir={theme.direction}>
              <Residents
                condominiumId={selectedCondominium?._id || ""}
                alert={alertRef.current}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={3} dir={theme.direction}>
              <Managers
                selectedCondominium={selectedCondominium}
                alert={alertRef.current}
                setMode={setMode}
                getCondominiums={getCondominiums}
              />
            </TabPanel>
          </div>
        </div>
      </>
    );
  };

  const switchRender = () => {
    switch (mode) {
      case 0:
        return renderSelector();
      case 1:
        return renderTabs();
      default:
        return renderSelector();
    }
  };

  return (
    <div>
      <Dialog fullScreen open={showEnter} onClose={handleClose}>
        <DialogTitle id="responsive-dialog-title">
          Entrar como síndico
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" align="center">
            Insira o código do condomínio para entrar como síndico
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="cod"
              name="cod"
              label="Código do condomínio"
              value={formik.values.cod}
              onChange={(e) => {
                if (e.target.value.length > 6) {
                  return;
                }
                formik.setFieldValue("cod", e.target.value.toLowerCase());
              }}
              error={formik.touched.cod && Boolean(formik.errors.cod)}
              helperText={formik.touched.cod && formik.errors.cod}
              style={{ marginTop: 10 }}
            />
            <TextField
              fullWidth
              id="username"
              name="username"
              label="Nome para aparecer no tablet"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              style={{ marginTop: 10 }}
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              type="password"
              label="Senha de acesso ao tablet"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              style={{ marginTop: 10 }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.btn}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => formik.handleSubmit()}
            color="primary"
            className={classes.btn}
            variant="contained"
          >
            Entrar
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert ref={alertRef} />
      {switchRender()}
    </div>
  );
};

export default Condominiums;
