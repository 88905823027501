import React, { useRef } from "react";

import MaterialTable from "material-table";
import { useConfirm } from "material-ui-confirm";

import axios from "config/axios";

//import useStyles from "./styles";

interface Props {
  condominiumId: string;
  alert: any;
}

const Blocks: React.FC<Props> = (props) => {
  //const classes = useStyles();

  const tableRef = useRef<any>();

  const alterAlert = props.alert.alterAlert;

  const confirm = useConfirm();

  return (
    <div>
      <MaterialTable
        options={{ exportButton: true, grouping: true, search: true }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "Nome",
            field: "name",
            type: "string",
            defaultSort: "asc",
          },
          {
            title: "_id",
            field: "_id",
            type: "string",
            hidden: true,
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/aptmanager/condominium/blocks`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                condominiumId: props.condominiumId,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error?.response?.data?.code) {
                  default:
                    props.alert?.alterAlert(
                      "Problema ao carregar dados",
                      "error"
                    );
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowAdd: async (newData: any) => {
            try {
              await axios.post("/aptmanager/condominium/create/block", {
                name: newData.name,
                condominiumId: props.condominiumId,
              });
              alterAlert("Bloco cadastrado com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Condomínio não encontrado", "warning");
                  break;
                case 1:
                  alterAlert("Bloco já cadastrado com esse nome", "warning");
                  break;
                default:
                  alterAlert("Problema ao cadastrar bloco", "error");
                  break;
              }
            }
          },
          onRowUpdate: async (newData, oldData) => {
            try {
              await axios.put(`/aptmanager/condominium/update/block`, {
                name: newData.name,
                condominiumId: props.condominiumId,
                blockId: newData._id,
              });

              alterAlert("Atualizado com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Condomínio não encontrado", "warning");
                  break;
                case 1:
                  alterAlert("Bloco não encontrado", "warning");
                  break;
                default:
                  alterAlert("Problema ao atualizar bloco", "error");
                  break;
              }
            }
          },
          onRowDelete: async (oldData) => {
            confirm({
              cancellationText: "Cancelar",
              confirmationText: "Sim excluir",
              description:
                "Ao excluir um bloco, você estará excluindo todos os apartamentos e moradores vinculados a ele",
              title: "Excluir excluir bloco, apartamentos e moradores?",
            })
              .then(async () => {
                try {
                  await axios.delete(
                    `/aptmanager/condominium/delete/block/${props.condominiumId}/${oldData._id}`
                  );
                  alterAlert("Bloco removido com sucesso", "success");
                  tableRef.current && tableRef?.current?.onQueryChange();
                } catch (error) {
                  console.log(error);
                  switch (error?.response?.data?.status) {
                    case 404:
                      alterAlert("Condomínio não encontrado", "warning");
                      break;
                    case 1:
                      alterAlert("Bloco não encontrado", "warning");
                      break;
                    default:
                      alterAlert("Problema ao remover bloco", "error");
                      break;
                  }
                }
              })
              .catch(() => {});
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
            deleteTooltip: "Excluir bloco",
            addTooltip: "Adicionar bloco",
            editTooltip: "Editar bloco",
            editRow: {
              deleteText: "Excluir bloco?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Blocos"
      />
    </div>
  );
};

export default Blocks;
