import React, { useRef, useState, useEffect } from "react";

import MaterialTable from "material-table";

import Select, { SelectOption } from "components/Select";

import axios from "config/axios";

//import useStyles from "./styles";

import { Block } from "types";
import { useCallback } from "react";
import { useConfirm } from "material-ui-confirm";

interface Props {
  condominiumId: string;
  alert: any;
}

const Apartments: React.FC<Props> = (props) => {
  const [blocks, setBlocks] = useState<Array<SelectOption>>([]);
  //const classes = useStyles();
  const alterAlert = props.alert.alterAlert;

  const getBlocks = useCallback(() => {
    axios
      .get(`/aptmanager/condominium/${props.condominiumId}/blocks`)
      .then(({ data }: { data: Array<Block> }) => {
        //console.log(data);
        const result: Array<SelectOption> = [];
        data.forEach((e) => {
          result.push({ value: e._id, label: e.name });
        });
        setBlocks(result);
      })
      .catch((error) => {
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alterAlert("Problema ao carregar blocos", "error");
            break;
        }
      });
  }, [alterAlert, props.condominiumId]);

  useEffect(() => {
    getBlocks();
  }, [getBlocks]);

  const tableRef = useRef<any>();

  const confirm = useConfirm();

  return (
    <div>
      <MaterialTable
        options={{ exportButton: true, grouping: true, search: true }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "Bloco",
            field: "block.name",
            editable: "onAdd",
            type: "string",
            sorting: false,
            editComponent: (editEvent) => {
              return (
                <>
                  <Select
                    placeholder="Selecione o bloco"
                    onChange={(e) => editEvent.onChange(e?.value)}
                    fullOptions={blocks}
                  />
                </>
              );
            },
          },
          {
            title: "Apartamento",
            field: "name",
            type: "string",
            editable: "onAdd",
          },
          {
            title: "id",
            field: "_id",
            type: "string",
            editable: "never",
            hidden: true,
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/aptmanager/condominium/apartments`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                condominiumId: props.condominiumId,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error?.response?.data?.code) {
                  default:
                    props.alert?.alterAlert(
                      "Problema ao carregar dados",
                      "error"
                    );
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowAdd: async (newData: any) => {
            try {
              await axios.post("/aptmanager/condominium/create/apartment", {
                name: newData.name,
                block: newData.block.name,
                condominiumId: props.condominiumId,
              });
              alterAlert("Apartamento cadastrado com sucesso", "success");
            } catch (error) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alterAlert("Condomínio não encontrado", "warning");
                  break;
                case 1:
                  alterAlert(
                    "Apartamento já cadastrado com esse nome nesse bloco",
                    "warning"
                  );
                  break;
                default:
                  alterAlert("Problema ao cadastrar apartamento", "error");
                  break;
              }
            }
          },
          onRowDelete: async (oldData) => {
            confirm({
              cancellationText: "Cancelar",
              confirmationText: "Sim excluir",
              description:
                "Ao excluir um apartamento, você estará excluindo todos os moradores vinculados a ele",
              title: "Excluir excluir apartamento e moradores?",
            })
              .then(async () => {
                try {
                  await axios.delete(
                    `/aptmanager/condominium/delete/apartment/${props.condominiumId}/${oldData._id}`
                  );
                  alterAlert("Apartamento removido com sucesso", "success");
                  tableRef.current && tableRef?.current?.onQueryChange();
                } catch (error) {
                  console.log(error);
                  switch (error?.response?.data?.status) {
                    case 404:
                      alterAlert("Apartamento não encontrado", "warning");
                      break;
                    case 1:
                      alterAlert("Bloco não encontrado", "warning");
                      break;
                    default:
                      alterAlert("Problema ao remover apartamento", "error");
                      break;
                  }
                }
              })
              .catch(() => {});
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
            deleteTooltip: "Excluir apartamento",
            addTooltip: "Adicionar apartamento",
            editTooltip: "Editar apartamento",
            editRow: {
              deleteText: "Excluir apartamento?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Apartamentos"
      />
    </div>
  );
};

export default Apartments;
