import React, { useState, forwardRef, useImperativeHandle } from "react";

import { Snackbar } from "@material-ui/core";
import { AlertSeverity } from "types";

import Alert from "components/Alert";

interface Props {}

export type CustomAlertRef = {
  alterAlert(
    msg?: string | undefined,
    severity?: AlertSeverity | undefined
  ): void;
};

const CustomAlert = forwardRef<CustomAlertRef, Props>((props, ref) => {
  const [showAlert, setShowAlert] = useState(false),
    [alertSeverity, setAlertSeverity] = useState<AlertSeverity>(),
    [alert, setAlert] = useState("");

  useImperativeHandle(ref, () => ({
    alterAlert(msg?: string, severity?: AlertSeverity) {
      if (showAlert && !msg && !severity) {
        setShowAlert(false);
      } else {
        setShowAlert(true);
        setAlert(msg || "");
        setAlertSeverity(severity);
      }
    },
  }));
  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={showAlert}
      onClose={handleAlertClose}
      message={alert}
    >
      <Alert onClose={handleAlertClose} severity={alertSeverity}>
        {alert}
      </Alert>
    </Snackbar>
  );
});

export default CustomAlert;
