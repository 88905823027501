import Axios from "axios";
import { getUserToken } from "functions/userToken";

let host = "https://api.lockyt.com";

if (process.env.NODE_ENV === "development") {
  host = "http://localhost:8080";
  //host = "http://192.168.100.98:8080";
}

const api = Axios.create({
  baseURL: host,
  headers: {
    "User-Timezone": new Date().getTimezoneOffset(),
  },
});

api.interceptors.request.use(async (config) => {
  const token = getUserToken();
  if (token != null) {
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
